<template>
	<div
		class="esg-course__description"
		:class="{'show-materials':isShow}"
	>
		<div
			class="esg-course__toggle"
			@click="show"
		>
			Показать содержание
		</div>
		<slide-up-down
			:active="isShow"
			:duration="durationAnimation"
		>
			<div
				class="wrap"
			>
				<div class="esg-course__text text">
					<p>
						{{ courseDescription }}
					</p>
				</div>
				<div class="esg-course__table">
					<div
						v-for="(material, index) in materials"
						:key="index"
						class="esg-course__row"
					>
						<div class="esg-course__material">
							{{ material.title }}
						</div>
						<div class="esg-course__type">
							{{ material.type_material }}
						</div>
						<div class="esg-course__duration">
							{{ material.duration | durationFilter }}
						</div>
					</div>
				</div>
				<div class="esg-course__result">
					{{ resultText }}
				</div>
			</div>
		</slide-up-down>
	</div>
</template>

<script>
import {mapGetters} from 'vuex'
export default {
	props: {
		materials: {
			type: Array,
			default: () => []
		},
		courseDescription: {
			type: String,
			default: '',
		},
		resultText: {
			type: String,
			default: '',
		}
	},
	data() {
		return {
			isShow: false
		};
	},
	computed: {
		...mapGetters('user', {
			isLogged: 'isLoggedIn',
			user: 'userInfo'
		}),
				durationAnimation() {
			if(window.innerWidth > 768) {
				return 600
			} else {
				return 0
			}
		},
		isStarted() {
			if (this.isSended) return true;
			return (
				this.user &&
				this.user.req_course_user &&
				this.user.req_course_user.some(
					req_course => req_course.course === this.urlCourseID
				)
			);
		}
	},
	methods: {
		show() {
			this.isShow = !this.isShow;
		}
	}
};
</script>
