<template>
	<div class="esg-course__item">
		<div class="esg-course__body">
			<div
				class="esg-course__pic"
				:style="`background-image: url(${course.course_image});`"
			/>
			<div class="esg-course__content">
				<div class="esg-course__header">
					<router-link
						class="esg-course__title"
						:to="
							`/courses/${course.course_slug}-${course.course_id}`
						"
						target="_blank"
					>
						{{ course.title }}
					</router-link>
					<button
						class="base-button base-button--orange-pink base-button--sm preloader-wrap"
						:class="{'preloader--on':isLoadingRegistred}"
						@click="openCourse(course.course_session_link)"
					>
						<span v-if="!isLoadingRegistred">{{
							buttonSendText
						}}</span>
						<div
							v-else
							class="button-preloader-icons"
						>
							<div class="button-preloader-icon" />
							<div class="button-preloader-icon" />
							<div class="button-preloader-icon" />
						</div>
					</button>
				</div>
				<div class="esg-course__footer">
					<div class="esg-course__info">
						<div class="esg-course__point">
							<div
								class="esg-course__icon"
								:style="
									`/img/landing/esg/icon__course-${course.type_material_icon}.svg`
										| bgrImageCover
								"
							/>
							{{ course.type_material }}
						</div>
						<div class="esg-course__point">
							<div
								class="esg-course__icon"
								:style="
									`/img/landing/esg/icon__course-time.svg`
										| bgrImageCover
								"
							/>
							{{ course.duration | durationFilter }}
						</div>
						<div class="esg-course__point">
							<div
								class="esg-course__icon"
								:style="
									'/img/landing/esg/icon__course-list.svg'
										| bgrImageCover
								"
							/>
							{{
								course.materials_count
									| toPlural("тема", "темы", "тем")
							}}
						</div>
					</div>
				</div>
			</div>
		</div>
		<Materials
			:materials="course.courseesgmaterial_set"
			:course-description="course.description"
			:result-text="course.result"
		/>
	</div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import Materials from './СourseMaterials.vue';
export default {
	components: {
		Materials
	},
	props: {
		course: {
			type: Object,
			default: () => {}
		}
	},
	data() {
		return {
			isLoadingRegistred: false
		};
	},
	computed: {
		...mapGetters('user', {
			isLogged: 'isLoggedIn',
			user: 'userInfo'
		}),
		isStarted() {
			if (this.isSended) return true;
			return (
				this.user &&
				this.user.req_course_user &&
				this.user.req_course_user.some(
					req_course => req_course.course === this.urlCourseID
				)
			);
		},
		urlCourseID() {
			return this.course.course_id;
		},
		buttonSendText() {
			if (this.isStarted) {
				return 'Продолжить изучение';
			} else if (this.isLogged) {
				return 'Записаться';
			} else {
				return 'Зарегистрироваться';
			}
		}
	},
	mounted() {
		if (!this.isStarted) {
			this.$emit('getCourse', this.course.course_id);
		}
	},
	methods: {
		...mapActions('tb', ['startCourse']),
		...mapActions('user', ['detail']),
		openCourse(link) {
			if (!this.isLogged) {
				this.modalOpen('regIn');
			}
			else if (!this.isStarted && this.isLogged) {
				this.isLoadingRegistred = true;
				this.startCourse({
					user: this.user.id,
					course: this.course.course_id,
					course_session: this.course.course_sessions
				}).then(() => {
					this.detail();
					this.isLoadingRegistred = false;
					this.openInNewWindow(link);
				});
			} else {
				this.openInNewWindow(link);
			}
		},
		openInNewWindow(url) {
			window.open(url, 'self');
		},
	}
};
</script>
