<template>
	<div class="page page-landing">
		<main>
			<h1 class="seo-title">
				Бесплатные онлайн-курсы
			</h1>

			<div class="esg-page__block">
				<div class="esg-header__block">
					<div class="content">
						<h1 class="esg-header__title">
							Погрузись в <br>
							устойчивое развитие <br>
							вместе с Лифтом в <br>
							будущее!
						</h1>
						<h2 class="esg-header__subtitle">
							Пройди серию онлайн курсов, чтобы разобраться в
							<br>
							актуальном тренде!
						</h2>
						<button
							class="base-button base-button--orange-pink base-button--md  preloader-wrap"
							:class="{'disabled':isRegistered}"
							@click="signUp"
						>
							<span v-if="!isLoadingRegistred">{{ buttonSignUpText }}</span>
							<div
								v-else
								class="button-preloader-icons"
							>
								<div class="button-preloader-icon" />
								<div class="button-preloader-icon" />
								<div class="button-preloader-icon" />
							</div>
						</button>
						<div class="esg-header__pic">
							<img
								:src="'/img/landing/esg/header__pic.png' | prodSrcImage"
								alt="Устойчивое развитие с Лифтом в будущее"
							>
						</div>
					</div>
				</div>

				<div class="esg-feature__block">
					<div class="content">
						<div class="esg-feature__group">
							<div class="esg-feature__item">
								<img
									:src="'/img/landing/esg/feature__icon-1.png' | prodSrcImage"
									alt="Бесплатно"
									class="esg-feature__icon"
								>
								<div class="esg-feature__content">
									<div class="esg-feature__title">
										Бесплатно
									</div>
									<div class="esg-feature__subtitle">
										для всех желающих
									</div>
								</div>
							</div>
							<div class="esg-feature__item">
								<img
									:src="'/img/landing/esg/feature__icon-2.png'| prodSrcImage"
									alt="Открытый доступ"
									class="esg-feature__icon"
								>
								<div class="esg-feature__content">
									<div class="esg-feature__title">
										Доступ
									</div>
									<div class="esg-feature__subtitle">
										в любое время
									</div>
								</div>
							</div>
							<div class="esg-feature__item">
								<img
									:src="'/img/landing/esg/feature__icon-3.png'| prodSrcImage"
									alt="Сертификат за каждый курс"
									class="esg-feature__icon"
								>
								<div class="esg-feature__content">
									<div class="esg-feature__title">
										Сертификат
									</div>
									<div class="esg-feature__subtitle">
										за каждый пройденный курс
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>

				<div class="esg-development__block">
					<div class="content">
						<div class="esg-development__grid">
							<img
								:src="'/img/landing/esg/development__pic.png'| prodSrcImage"
								alt="Устойчивое развитие"
								class="esg-development__pic"
							>
							<div class="esg-development__content">
								<h3 class="esg-section__title">
									Что такое <br>
									устойчивое развитие?
								</h3>
								<div class="esg-development__text text">
									<p>
										Устойчивое развитие - развитие общества,
										при котором удовлетворение потребностей
										нынешнего поколения не подрывает
										возможности удовлетворения потребностей
										будущих поколений.
									</p>
									<p>
										Другими словами, такое развитие, когда
										мы не используем ресурсы тех, кто будет
										жить на земле после нас.
									</p>
								</div>
								<button
									class="base-button base-button--orange-pink base-button--md preloader-wrap"
									:class="{'disabled':isRegistered}"
									@click="signUp"
								>
									<span v-if="!isLoadingRegistred">{{ buttonSignUpText }}</span>
									<div
										v-else
										class="button-preloader-icons"
									>
										<div class="button-preloader-icon" />
										<div class="button-preloader-icon" />
										<div class="button-preloader-icon" />
									</div>
								</button>
							</div>
						</div>
					</div>
				</div>

				<div class="esg-definition__block">
					<div class="content">
						<div class="esg-definition__header">
							<h3 class="esg-section__title">
								Что такое <br>
								ESG?
							</h3>
							<div class="esg-definition__text text text--dark">
								<p>
									Аббревиатуру ESG можно расшифровать, как
									«окружающая среда, социальная политика и
									корпоративное управление». В широком смысле
									это устойчивое развитие коммерческой
									деятельности, которое строится на следующих
									принципах:
								</p>
							</div>
						</div>
						<div class="esg-scheme__block">
							<div class="esg-scheme__item">
								<div
									class="esg-scheme__header esg-scheme__header--e"
								>
									<div class="esg-scheme__sign">
										E
									</div>
									<div class="esg-scheme__name">
										Окружающая среда
									</div>
								</div>
								<div
									class="esg-scheme__list esg-scheme__list--e"
								>
									<h4
										class="esg-scheme__title esg-scheme__title--e1"
									>
										<img
											:src="'/img/landing/esg/icon__scheme-e1.svg'| prodSrcImage"
											alt="Стабильность климата"
											class="esg-scheme__icon"
										>
										Стабильность климата
									</h4>
									<h4
										class="esg-scheme__title esg-scheme__title--e2"
									>
										<img
											:src="'/img/landing/esg/icon__scheme-e2.svg'| prodSrcImage"
											alt="Природные ресурсы"
											class="esg-scheme__icon"
										>
										Природные ресурсы
									</h4>
									<h4
										class="esg-scheme__title esg-scheme__title--e3"
									>
										<img
											:src="'/img/landing/esg/icon__scheme-e3.svg'| prodSrcImage"
											alt="Управление отходами"
											class="esg-scheme__icon"
										>
										Управление отходами
									</h4>
									<h4
										class="esg-scheme__title esg-scheme__title--e4"
									>
										<img
											:src="'/img/landing/esg/icon__scheme-e4.svg'| prodSrcImage"
											alt="Инновации"
											class="esg-scheme__icon"
										>
										Инновации
									</h4>
								</div>
							</div>
							<div class="esg-scheme__item">
								<div
									class="esg-scheme__header esg-scheme__header--s"
								>
									<div class="esg-scheme__sign">
										S
									</div>
									<div class="esg-scheme__name">
										Социум
									</div>
								</div>
								<div
									class="esg-scheme__list esg-scheme__list--s"
								>
									<h4
										class="esg-scheme__title esg-scheme__title--s1"
									>
										<img
											:src="'/img/landing/esg/icon__scheme-s1.svg'| prodSrcImage"
											alt="Управление человеческим капиталом"
											class="esg-scheme__icon"
										>
										Управление человеческим капиталом
									</h4>
									<h4
										class="esg-scheme__title esg-scheme__title--s2"
									>
										<img
											:src="'/img/landing/esg/icon__scheme-s2.svg'| prodSrcImage"
											alt="Разнообразие, равенство и инклюзия"
											class="esg-scheme__icon"
										>
										Разнообразие, равенство <br>и инклюзия
									</h4>
									<h4
										class="esg-scheme__title esg-scheme__title--s3"
									>
										<img
											:src="'/img/landing/esg/icon__scheme-s3.svg'| prodSrcImage"
											alt="Цепочка создания ценности"
											class="esg-scheme__icon"
										>
										Цепочка создания ценности
									</h4>
									<h4
										class="esg-scheme__title esg-scheme__title--s4"
									>
										<img
											:src="'/img/landing/esg/icon__scheme-s4.svg'| prodSrcImage"
											alt="Общество"
											class="esg-scheme__icon"
										>
										Общество
									</h4>
								</div>
							</div>
							<div class="esg-scheme__item">
								<div
									class="esg-scheme__header esg-scheme__header--g"
								>
									<div class="esg-scheme__sign">
										G
									</div>
									<div class="esg-scheme__name">
										Управление
									</div>
								</div>
								<div
									class="esg-scheme__list esg-scheme__list--g"
								>
									<h4
										class="esg-scheme__title esg-scheme__title--g1"
									>
										<img
											:src="'/img/landing/esg/icon__scheme-g1.svg'| prodSrcImage"
											alt="Управление рисками"
											class="esg-scheme__icon"
										>
										Управление рисками
									</h4>
									<h4
										class="esg-scheme__title esg-scheme__title--g2"
									>
										<img
											:src="'/img/landing/esg/icon__scheme-g2.svg' | prodSrcImage "
											alt="Корпоративное управление"
											class="esg-scheme__icon"
										>
										Корпоративное управление
									</h4>
									<h4
										class="esg-scheme__title esg-scheme__title--g3"
									>
										<img
											:src="'/img/landing/esg/icon__scheme-g3.svg'| prodSrcImage"
											alt="Недопущение воровства и коррупции"
											class="esg-scheme__icon"
										>
										Недопущение воровства <br>и коррупции
									</h4>
									<h4
										class="esg-scheme__title esg-scheme__title--g4"
									>
										<img
											:src="'/img/landing/esg/icon__scheme-g4.svg'| prodSrcImage"
											alt="Прозрачность"
											class="esg-scheme__icon"
										>
										Прозрачность
									</h4>
								</div>
							</div>
							<img
								:src="'/img/landing/esg/scheme__pic.png'| prodSrcImage"
								alt="ESG - Environmental, Social, Governance"
								class="esg-scheme__pic"
							>
						</div>
					</div>
				</div>

				<div class="esg-important__block">
					<div class="content">
						<h3 class="esg-section__title">
							Почему это важно для <br>
							каждого?
						</h3>
						<div class="esg-important__group">
							<div class="esg-important__item">
								<div class="esg-important__pic">
									<img
										:src="'/img/landing/esg/important__icon-1.png'| prodSrcImage"
										alt="Финансовая поддержка"
										class="esg-important__icon"
									>
								</div>
								<h4 class="esg-important__title">
									Финансовая <br>
									поддержка отраслей
								</h4>
								<div class="text">
									<p>
										Крупнейшие финансовые организации
										озвучили планы по отказу от поддержки
										отраслей с производствами, которые
										негативно влияют на цели устойчивого
										развития. А значит в перспективе эти
										компании и отрасли не смогут быть
										перспективными работодателями.
									</p>
								</div>
							</div>
							<div class="esg-important__item">
								<div class="esg-important__pic">
									<img
										:src="'/img/landing/esg/important__icon-2.png'| prodSrcImage"
										alt="Личная ответственность"
										class="esg-important__icon"
									>
								</div>
								<h4 class="esg-important__title">
									Личная <br>
									ответственность
								</h4>
								<div class="text">
									<p>
										Знание повестки УР и принципов ESG,
										помогает обычному человеку внести свой
										вклад в будущее и делает его более
										ответственным.
									</p>
								</div>
							</div>
							<div class="esg-important__item">
								<div class="esg-important__pic">
									<img
										:src="'/img/landing/esg/important__icon-3.png'| prodSrcImage"
										alt="Востребованность на рынке"
										class="esg-important__icon"
									>
								</div>
								<h4 class="esg-important__title">
									Востребованность <br>
									на рынке труда
								</h4>
								<div class="text">
									<p>
										Наконец самые перспективные работодатели
										будут ориентироваться при найме
										персонала еще и на зрелость кандидата с
										его вовлеченностью в современную
										повестку.
									</p>
								</div>
							</div>
						</div>
					</div>
				</div>

				<div
					v-if="informations"
					class="wrap"
				>
					<div
						v-for="category in informations.categoryes"
						:key="category.id"
						class="esg-course__block"
					>
						<div
							v-if="informations.categoryes"
							class="content"
						>
							<h3 class="esg-header__title">
								{{ category.title }}:
							</h3>
							<div class="esg-course__group">
								<Course
									v-for="(course,
										index) in category.courseesg_set"
									:key="index"
									:course="course"
									@getCourse="getCourses"
								/>
							</div>
						</div>
					</div>
				</div>

				<div class="esg-speaker__block">
					<div class="content">
						<h3 class="esg-section__title">
							Спикеры
						</h3>
						<div class="esg-speaker__group">
							<div
								v-for="speaker in speakers"
								:key="speaker.id"
								class="esg-speaker__item"
							>
								<div
									class="esg-speaker__avatar"
									:style="
										`background-image: url(${speaker.photo});`
									"
								/>
								<div class="esg-speaker__content">
									<h4 class="esg-speaker__name">
										{{ speaker.name }}
									</h4>
									<div class="esg-speaker__text text">
										<p>
											{{ speaker.position }}
										</p>
									</div>
								</div>
							</div>
						</div>
						<button
							v-if="!isShowAllSpeakers"
							class="base-button base-button--pink-purple base-button--md"
							@click="showAllSpikers"
						>
							И еще {{ countSpeakers }} спикеров
						</button>
					</div>
				</div>

				<div class="esg-review__block">
					<div class="content">
						<h3 class="esg-section__title">
							Отзывы
						</h3>
						<div class="esg-review__slider">
							<div
								v-if="informations.reviews"
								class="slider"
							>
								<VueSlickCarousel v-bind="carouselSettings">
									<div
										v-for="card in informations.reviews"
										:key="card.name"
									>
										<div class="esg-review__item">
											<div class="esg-review__name">
												{{ card.name }}
											</div>
											<div
												class="esg-review__text text text--dark"
											>
												<p>
													{{ card.text }}
												</p>
											</div>
										</div>
									</div>
								</VueSlickCarousel>
							</div>
						</div>
						<button
							class="base-button base-button--orange-pink base-button--md preloader-wrap"
							:class="{'disabled':isRegistered}"
							@click="signUp"
						>
							<span v-if="!isLoadingRegistred">{{ buttonSignUpText }}</span>
							<div
								v-else
								class="button-preloader-icons"
							>
								<div class="button-preloader-icon" />
								<div class="button-preloader-icon" />
								<div class="button-preloader-icon" />
							</div>
						</button>
					</div>
				</div>

				<div class="esg-about__block">
					<div class="content">
						<h3 class="esg-section__title">
							О нас
						</h3>
						<div class="text text--dark text--md">
							<p>
								Всероссийская программа профориентации и
								профессионального развития, способствующая
								повышению карьерных возможностей в компаниях АФК
								«Система».
							</p>
							<p>
								Онлайн платформа
								<a
									href="https://lift-bf.ru/"
									target="_blank"
								>«Лифт в будущее»</a>
								дает школьникам и студентам по всей стране
								равный и бесплатный доступ к сервису
								профориентации, образовательным курсам и
								помогает найти первую работу. Программа
								отличается целенаправленной привязкой к практике
								и к работодателям: действующие специалисты
								компаний Группы АФК «Система» ведут курсы на
								платформе, рассказывают о своих профессиях,
								рекрутеры Корпорации наблюдают за результатами
								участников, чтобы выявить лучших кандидатов,
								которым предлагаются стажировки и вакансии для
								молодых специалистов.
							</p>
							<p>
								Проект реализуется благотворительным фондом
								<a
									href="https://bf.sistema.ru"
									target="_blank"
								>«Система»</a>
							</p>
						</div>
					</div>
				</div>

				<div
					v-if="informations.partners"
					class="esg-partner__block"
				>
					<div class="content">
						<h3 class="esg-section__title">
							Партнеры
						</h3>
						<div class="partners-slider esg-slider">
							<div class="slider">
								<VueSlickCarousel v-bind="carouselSettings">
									<a
										v-for="partner in informations.partners"
										:key="partner.name"
										:href="partner.link"
										target="_blank"
										class="slide"
									>
										<div class="partner">
											<img
												:src="partner.image"
												alt="Martin"
												class="pic"
											>
										</div>
									</a>
								</VueSlickCarousel>
							</div>
						</div>
					</div>
				</div>
			</div>
		</main>
	</div>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex';
import Course from './Course';
export default {
	components: {
		Course
	},
	data() {
		return {
			isSended: false,
			isShowAllSpeakers: false,
			coursesSubscriptions:[],
			isLoadingRegistred: false,
		};
	},
	computed: {
		...mapGetters('esg', {
			isSignUp: 'getIsSignUp',
			informations: 'getInf',
			isRegistered: 'getIsRegistered',
		}),
		...mapGetters('user', {
			isLogged: 'isLoggedIn',
			userID: 'userID',
		}),
			...mapState('user', [
            'id'
			]),
		speakers() {
			if (
				!this.isShowAllSpeakers &&
				this.informations.speakers
			) {
				return this.informations.speakers.slice(0, 6);
			} else {
				return this.informations.speakers;
			}
		},
		countSpeakers() {
			if (this.informations.speakers) {
				return this.informations.speakers.length - 6;
			} else {
				return 0;
			}
		},
		buttonSignUpText() {
			if(!this.isLogged) {
				return 'Зарегистрироваться'
			} else if(this.isLogged && !this.isRegistered) {
				return 'Записаться'
			} else {
				return 'Вы уже записаны'
			}
		}
	},
    created() {
        this.getInf();
        this.getIsRegistered(this.userID)
    },
    mounted() {
        this.$scrollTo(document.querySelector('header.header-role'));
    },
	methods: {
		...mapActions('esg', ['signUpEvent', 'getInf','getIsRegistered']),
      ...mapActions('user', [
            'detail',
        ]),
		signUp() {
			if(!this.isLogged) {
				this.modalOpen('regIn');
			} else {
				this.isLoadingRegistred = true
				this.signUpEvent({'courses':this.coursesSubscriptions}).then(() => {
					this.getIsRegistered(this.userID)
					this.modalOpen('is-registered-event')
					this.isLoadingRegistred = false
					this.detail()
				})
			}
		},
		getCourses(val) {
			this.coursesSubscriptions.push(val)
		},
		showAllSpikers() {
			this.isShowAllSpeakers = true;
		}
	}
};
</script>
<style lang="less">
@import "../../../assets/styles/pages/esg/index.less";
.disable {
	pointer-events: none;
}
.preloader-wrap {
	position:relative;
}
.esg-slider .slick-track {
    height: 102px;
}
</style>
